@import '../../../../_metronic/assets/sass/core/components/mixins/menu';

.toggle-language {
    margin-right: 20px;
    position: relative;
};

.current-language:hover~.menu-sub {
    display: block;
};
.submenu {
    padding: 13px 20px;
    .menu-sub {
        position: absolute;
        display: none;
        background-color: white;
        top: 35px;
        border-radius: 8px;
        .menu-item {
            width: 100%;
            .menu-link {
                padding: 0;
                width: 100%;
            };
        }
        .submenu-link {
            color: var(--kt-gray-600) !important;
            border-radius: 0.475rem;
            &:hover {
                color: var(--kt-menu-link-color-hover) !important;
                background-color: var(--kt-menu-link-bg-color-hover);
            }
        }
    };
};

.menu-sub:hover {
    display: block;
};

.text-notifi {
    color: #1b5d82 !important;
}