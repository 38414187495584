@import "../../styles/variable";

.category-dialog {
    &-titel-status {
        font-weight: initial !important;
        font-style: italic !important;
        color: rgba(0, 0, 0, 0.6);
    }
}
.vertical-tabs {
    display: flex;
    align-items: stretch;
    gap: 10px;
    background-color: white;
    padding: 16px 16px 16px 0;

    .customs-tabs {
        height: auto;
        border-bottom: none;
        border-right: 1px solid #eff2f5;

        .nav-link {
            padding: 8px !important;
            width: 100%;

            .label {
                text-align: right;

                span {
                    padding: 0 16px;
                }
            }

            &.active .label span {
                padding: 0 16px;

                &::after {
                    width: 2px;
                    height: 26px;
                    top: 50%;
                    left: 100%;
                }
            }
        }
    }

    .tab-content {
        flex: 1;
    }
}

.area-category-edit-page {
    background: $color-white;

    .treelist-container {
        padding-right: 5px;
        box-shadow: inset -5px 0 6px -6px rgba(0, 0, 0, 0.25);
    }

    .card-header {
        transition: all linear 0.25s;
        border: none;
        border-radius: 4px;
        width: calc(100% + 5px);

        &.selected {
            border: 1px solid #e7eaed;
            border-right: none ;
            border-left-color: $color-steel-blue;
            border-left-width: 5px;
            background-color: $color-white;
        }

        .btn-actions {
            margin-top: 2px;
            display: none;
        }

        &:hover {
            background-color: #e7eaed !important;
            .btn-actions {
                display: block;
            }
        }
    }
    .root-info {
        box-shadow: 0px 1px 5px 1px #ddd;
    }
}