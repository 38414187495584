//
// Form Select
//

.form-select {
    box-shadow: none !important;

    // Solid style
	&.form-select-solid {
		@include placeholder(var(--kt-input-solid-placeholder-color));
		background-color: var(--kt-input-solid-bg);
		border-color: var(--kt-input-solid-bg);
		color: var(--kt-input-solid-color);
		transition: $transition-input;
		
		.dropdown.show > &,
		&:active,
		&.active,
		&:focus,
		&.focus {
			background-color: var(--kt-input-solid-bg-focus);
			border-color: var(--kt-input-solid-bg-focus) !important;
			color: var(--kt-input-solid-color);
			transition: $transition-input;
		}
	}

	// Transparent style
	&.form-select-transparent {
		@include placeholder(var(--kt-input-placeholder-color));
		background-color: transparent;
		border-color: transparent;
		color: $input-color;

		.dropdown.show > &,
		&:active,
		&.active,
		&:focus,
		&.focus {
			background-color: transparent;
			border-color: transparent !important;
			color: $input-color;
		}
	}
}
