.perform-evaluate {
  margin-left: 4px;

  &-title {
    padding-top: 24px;
    display: flex;
  }
  &-title-id {
    font-weight: inherit;
    font-style: italic;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 12px;
  }
}

.form-check-input {
  width: 1.25rem;
  height: 1.25rem;
}

.bg-color-success {
  td {
    background-color: #00a91fc7 !important;
  }
};

.bg-color-warning {
  td {
    background-color: #FFB200 !important;
  }
};