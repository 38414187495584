
.tree-view-table {
    .expand-icon-wrapper {
        .bi {
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 999px;
            margin: 0 auto;
            transition: all linear 0.25s;
            color: black;

            &:hover {
                background-color: #e7eaed;
            }
        }
    }

    .bg-level-0 {
        background: #ffffff;
    }

    .bg-level-1 {
        background: #f5f5f5;
    }

    .bg-level-2 {
        background: #e0e0e0;
    }

    .bg-level-3 {
        background: #c8c8c8;
    }

    .bg-level-4 {
        background: #b0b0b0;
    }

    .bg-level-5 {
        background: #a0a0a0;
    }
    
    min-height:300px;
}