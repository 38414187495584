.container-custom-color-picker {
    .form-label {
        margin-bottom: 4px;
    }
    .custom-color-picker {
        padding: 1px;
        height: 32px;
        width: 100%;
        min-width: 50px;
    }
};

.container-input-color {
    display: flex;
    justify-content: center;
    .custom-color-picker {
        padding: 1px;
        height: 35px;
        width: 50%;
        min-width: 100px;
    }
}