@import '../../modules/styles/variable';
.divider {
    width: 100%;
    height: 2px;
    border-bottom: 2px solid #eff2f5;
}

.treelist-criteria-container {
    border-right: 1px solid $color-gray-200;
    box-shadow: inset -5px 0 6px -6px $color-text-shadow;
    padding: 15px 0;
    margin-bottom: 0 !important;
}

.detail-criteria-container {
    margin-bottom: 0 !important;
}

.detail-criteria-container-scroll {
    max-height: calc(100vh - 280px);
    overflow-y: auto;
}

.save-btn-sticky {
    z-index: 10;
    position: sticky;
    padding-top: 8px;
    padding-bottom: 15px;
    top: -18px;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
}

.custom-input-answerNumeric {
    min-width: 75px ;
    padding-right: 0;
}