.programs-box {
    overflow-y: auto;
    .header-box {
        position: absolute;
        top: -1px;
        left: 24px;
        right: 30px;
        padding: 15px;
        background: white;
        display: flex;
        align-items: center;
    }
}

.scrollbar-width-thin {
    scrollbar-width: thin;
    scrollbar-color: var(--kt-scrollbar-color) transparent;
}

.text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 85%;
    margin-left: 5px;
}
