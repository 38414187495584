@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@font-face {
  font-family: "SF Pro Display";
  src: url('../../../_metronic/assets/font/sf-pro-display-cdnfonts/SFPRODISPLAYREGULAR.OTF') format('opentype');
  font-weight: normal;
}

* {
  // font-family: "SF Pro Display", sans-serif;
  // font-family: Helvetica, sans-serif;
  font-family: Roboto, sans-serif;
}

.heading-1 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: -0.03em;
}

.heading-2 {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: -0.01em;
}

.heading-3 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
}

.heading-4 {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
}

.heading-5 {
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 140%;
}

.supertitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  /* identical to box height, or 17px */

  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 150%;
}

.body-normal {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 155%;
}

.body-normal-1 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
}

.body-normal-2 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 145%;
}

.body-bold {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 155%;
}

.body-bold-1 {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 145%;
}

.body-bold-2 {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 145%;
}

.font-control {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 127%;
}

.font-control-placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.white {
  color: white !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fs-30 {
  font-size: 30px !important;
}