.roles {
  .form-add-role {
    border: 1px solid var(--kt-border-color);
    border-radius: 5px;

    .role-info {
      margin-right: calc(1.0 * var(--bs-gutter-x));
      margin-left: calc(1.0 * var(--bs-gutter-x));
    }

    .role-left-title {
      background-color: var(--kt-warning);
      padding: 7px 15px;
      color: var(--kt-app-blank-bg-color);
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      font-size: 15px;
    }

    .role-left-des {
      font-size: 16px;
      padding: 10px 15px 5px;
    }

    .role-func {
      padding-left: 20px;
      min-height: 306px;
    }
  }
}
