@import '../../modules/styles/variable';

.button-green-transparent {
    background: transparent !important;
    color: $color-theme-primary !important;
    border: 1px solid transparent !important;
    padding: 4px !important;
    font-size: 16px;
    line-height: 16px !important;
    border-radius: 4px;
    transition: all linear 0, 25s;

    &:hover {
        border: 1px solid $color-theme-primary !important;
        background-color: transparent !important;
    }

    &:disabled {
        background-color: $color-border-table !important;
        border-radius: 4px;
        color: color-text-disabled;
        pointer-events: none !important;
    }
}

.button-red-transparent {
    background: transparent !important;
    color: $color-dark-red !important;
    border: 1px solid transparent !important;
    padding: 4px !important;
    font-size: 16px;
    line-height: 16px !important;
    border-radius: 4px;
    transition: all linear 0, 25s;

    &:hover {
        border: 1px solid $color-dark-red !important;
        background-color: transparent !important;
    }

    &:disabled {
        background-color: $color-border-table !important;
        border-radius: 4px;
        color: color-text-disabled;
        pointer-events: none !important;
    }
}

.custom-treeview-criteria {
    .card {
		.has-children {
			padding-left: 20px;
		}
		
        .bg-light:not(.has-children) {
            background-color: $color-white !important;
            border-radius: 0;

            &.card-header {
                border-right: $color-white 1px solid !important;
                margin-right: -5px;
                border-left-color: var(--kt-primary-active) !important;
                border-left-width: 3px !important;
                border-radius: 4px 0 0 4px;
                padding-left: 42px;
                height: 45px;
                border: 1px solid #e7eaed;
            }
        }

        .card-header {
            min-height: 35px !important;
            height: 45px;
            border: none;


            >.form-label {
                font-size: 16px;
                // color: $color-gray;
                font-weight: bold;
                margin: 0 0 0 10px !important;
            }

			&:not(.has-children) {
				padding-left: 45px;
			}
        }
        
        .note-item-tree {
            display: flex;
            margin: 0;
            font-size: 0.7em;
            flex-wrap: nowrap;
            color:hsl(0, 0%, 50%);
        }
    }

    .card-body {
        padding: 0;

        .card-header {
            i {
                visibility: hidden;
            }

            >.form-label {
                font-size: 16px;
                color: $color-gray;
                font-weight: 400;
            }
        }
    }
}

.status {
    padding: 2px;
    width: 100px;
    border-radius: 30px;
    color: white;

    &.new {
        background-color: $color-orange;
    }

    &.in-progress {
        background-color: $color-steel-blue;
    }

    &.completed {
        background-color: $color-green;
    }

    &.other {
        background-color: $color-gray;
    }
}

.custom-button-plus {
    padding: 5px 8px 4px;
    gap: 8px;
    border-radius: 3.2px;
    border: 1px solid var(--kt-primary);
}

.color-crimson-red {
    color: $color-crimson-red !important;
}

.color-gray {
    color: var(--kt-gray-500) !important;
}

.color-blue-600 {
    color: $color-blue-600 !important;
}

.border-top-color-blue {
    border-top-color: $color-blue-600 !important;
}

.overflow-y-auto {
    overflow-y: auto !important;
}

.user-select-text {
    user-select: text !important;
}

.font-italic {
    font-style: italic !important;
}

.font-normal {
    font-style: normal !important;
}

.justify-center {
    display: flex;
    justify-content: center !important;
}

.space-around {
    display: flex;
    justify-content: space-around !important;
}

.space-between {
    display: flex;
    justify-content: space-between !important;
}

.align-center {
    align-items: center !important;
}

.space-evenly {
    display: flex;
    justify-content: space-evenly !important;
}

.min-h-600 {
    min-height: 600px;
}

.flex-row-label {
    display: flex;
    align-items: start;

    .text-lable-input {
        align-items: flex-start;
        // font-weight: 400;
        flex-shrink: 0;
        font-size: 14px;
        margin: 5px 6px 0 0;
    }

    // &>div {
    //   display: flex;
    //   width: 100%;
    // }
}

.hover-gray {
    &:hover {
        background-color: #f4f4f6;
    }
}
@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

.shake {
    animation: shake 1s;
}

.text-green {
    color: green !important;
}